<template>
  <div>
    <search-tool :title="$t('userList')" @search="search" @refresh="getUserList(currentPage)">
      <template #handle-buttons>
        <el-button size="small" type="primary" @click="$router.push('/admin/user/add')">
          {{ $t("handle.add") }}
        </el-button>
      </template>
    </search-tool>

    <el-table :data="userList" v-loading="loading">
      <el-table-column prop="userName" sortable :label="$t('userName')">
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="user"></svg-icon>
            <div class="content">
              <div class="title">{{ scope.row.name }}</div>
              <span class="desc">
                {{ moment(scope.row.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="organizationUUID" :label="$t('belongOrganization')">
        <template slot-scope="">
          <div class="title">{{ organizationName }}</div>
          <div class="desc">{{ organization }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="email" :label="$t('email')"> </el-table-column>

      <el-table-column width="100" :label="$t('handle.handle')">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <button class="table-row-handle">
              <i class="el-icon-more-outline"></i>
            </button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="edit(scope.row)">
                  {{ $t("handle.edit") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="deleteUser(scope.row)">
                  {{ $t("handle.delete") }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="$t('handle.edit')" :visible.sync="dialogVisible" top="30px" width="1000px">
      <div v-loading="detailLoading">
        <el-tabs v-model="activeName" type="card" v-if="dialogVisible && !detailLoading">
          <el-tab-pane :label="$t('basicConf')" name="general">
            <user-form :form="form" ref="generalForm" general />
          </el-tab-pane>
          <el-tab-pane :label="$t('userPermission')" name="permission">
            <user-form :form="form" ref="form" permission />
          </el-tab-pane>
          <el-tab-pane :label="$t('changePassword')" name="password">
            <user-form :form="form" ref="passwordForm" password />
          </el-tab-pane>

          <el-tab-pane :label="$t('resetAKSK')" name="secretKey">
            <ak-sk :form="form" />
          </el-tab-pane>
        </el-tabs>
      </div>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-pagination
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { userList, deleteUser, getUserInfo, userUpdate } from "api/user";
import { cloneDeep, isArray, includes } from "lodash";
import UserForm from "./Form.vue";
import AkSk from "./AKSK";
export default {
  components: { UserForm, AkSk },
  data() {
    return {
      userList: [],
      total: 0,
      currentPage: 1,
      currentPageSize: 10,
      searchText: "",
      loading: false,
      form: {},
      dialogVisible: false,
      submitLoading: false,
      detailLoading: true,
      activeName: "general"
    };
  },

  methods: {
    getUserList(page = 1, pageSize = this.currentPageSize, search = [this.searchText]) {
      this.loading = true;
      this.currentPage = page;
      userList(this.organization, { page, pageSize, search }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.userList = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    search(searchText) {
      this.searchText = searchText;
      this.getUserList(1);
    },

    currentChange(page) {
      this.currentPage = page;
      this.getUserList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getUserList(1, size);
    },

    edit(row) {
      this.dialogVisible = true;
      this.detailLoading = true;

      this.form = cloneDeep(row);

      getUserInfo(row.uuid).then(response => {
        if (response.code === 0) {
          this.detailLoading = false;

          this.$set(this.form, "accessKey", response.data.accessKey);
          this.$set(this.form, "secretKey", response.data.secretKey);

          let { groupUserBindings, groupUserRoleBindings, organizationUserBindings } = response.data.bindings;
          this.$set(this.form, "bindings", response.data.bindings);

          this.$nextTick(() => {
            if (groupUserBindings)
              this.$set(
                this.$refs["form"],
                "groupUserBindings",
                groupUserBindings.map(item => item.groupUUID)
              );

            if (groupUserRoleBindings)
              groupUserRoleBindings.forEach(item => {
                this.$set(this.$refs["form"].groupUserRoleBindings, item.providerUUID, item.roleUUID);
                this.$set(this.$refs["form"].effectBindings, item.providerUUID, item.effect);
              });

            if (organizationUserBindings) {
              organizationUserBindings.forEach(item => {
                if (item.organizationUUID == this.organization) {
                  this.$set(this.$refs["form"], "organizationAdminFlag", item.organizationAdminFlag);
                }
              });
            }
          });
        }
      });
    },

    submit() {
      if (this.activeName === "general") {
        this.$refs["generalForm"].$refs["form"].validate(valid => {
          if (valid) {
            this.$set(this.form, "updateType", ["general"]);
            this.submitFn();
          }
        });
      } else if (this.activeName === "password") {
        this.$refs["passwordForm"].$refs["form"].validate(valid => {
          if (valid) {
            this.$set(this.form, "updateType", ["password"]);
            this.submitFn();
          }
        });
      } else if (this.activeName === "secretKey") {
        this.$set(this.form, "updateType", ["secretKey"]);
        this.submitFn();
      } else {
        this.$refs["form"].$refs["form"].validate(valid => {
          if (valid) {
            this.$set(this.form, "updateType", []);
            let groupUserBindings = this.$refs["form"].groupUserBindings;
            let groupUserRoleBindings = this.$refs["form"].groupUserRoleBindings;
            let effectBindings = this.$refs["form"].effectBindings;
            // let organizationAdminFlag = this.$refs["form"].organizationAdminFlag;

            if (!this.form.bindings.groupUserRoleBindings) this.$set(this.form.bindings, "groupUserRoleBindings", []);
            if (!this.form.bindings.groupUserBindings) this.$set(this.form.bindings, "groupUserBindings", []);

            let existGroupUserRoleBindings =
              this.form.bindings.groupUserRoleBindings.length > 0
                ? this.form.bindings.groupUserRoleBindings.map(item => item.providerUUID)
                : [];

            for (let key in groupUserRoleBindings) {
              if (includes(existGroupUserRoleBindings, key)) {
                this.form.bindings.groupUserRoleBindings.forEach(item => {
                  let { roleUUID, providerUUID, effect } = item;

                  if (providerUUID === key) {
                    if (groupUserRoleBindings[providerUUID] != roleUUID) {
                      this.$set(item, "roleUUID", groupUserRoleBindings[providerUUID]);
                      this.$set(item, "operation", "UPDATE");
                    }

                    if (effectBindings[providerUUID] != effect) {
                      this.$set(item, "effect", effectBindings[providerUUID]);
                      this.$set(item, "operation", "UPDATE");
                    }
                  }
                });
              } else {
                this.form.bindings.groupUserRoleBindings.push({
                  providerUUID: key,
                  roleUUID: groupUserRoleBindings[key],
                  effect: effectBindings[key],
                  userUUID: this.form.uuid,
                  organizationUUID: this.organization
                });
              }
            }

            if (isArray(this.form.bindings.organizationUserBindings)) {
              if (this.form.bindings.organizationUserBindings.length > 0) {
                this.form.bindings.organizationUserBindings.forEach(item => {
                  if (item.organizationUUID == this.organization) {
                    if (item.organizationAdminFlag != this.$refs["form"].organizationAdminFlag) {
                      this.$set(item, "operation", "UPDATE");
                      this.$set(item, "organizationAdminFlag", this.$refs["form"].organizationAdminFlag);
                    }
                  }
                });
              }
            }

            if (isArray(this.form.bindings.groupUserBindings)) {
              if (this.form.bindings.groupUserBindings.length > 0) {
                this.form.bindings.groupUserBindings.forEach(item => {
                  if (!includes(groupUserBindings, item.groupUUID)) {
                    this.$set(item, "operation", "DELETE");
                  }
                });
              }

              groupUserBindings.forEach(item => {
                if (
                  !includes(
                    this.form.bindings.groupUserBindings.length > 0
                      ? this.form.bindings.groupUserBindings.map(item => item.groupUUID)
                      : [],
                    item
                  )
                ) {
                  this.form.bindings.groupUserBindings.push({
                    userUUID: this.form.uuid,
                    groupUUID: item,
                    organizationUUID: this.organization
                  });
                }
              });
            }

            this.submitFn().then(() => {
              if (this.form.name === this.$store.state.app.userName) this.$store.dispatch("app/getUserInfo");
            });
          }
        });
      }
    },

    submitFn() {
      return new Promise(resolve => {
        this.submitLoading = true;
        userUpdate(this.form).then(response => {
          this.submitLoading = false;
          if (response.code === 0) {
            this.getUserList(this.currentPage);
            this.dialogVisible = false;
            resolve();
          }
        });
      });
    },

    deleteUser(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          deleteUser(this.organization, item.uuid).then(response => {
            if (response.code === 0) {
              this.getUserList(this.currentPage);
            }
          });
        })
        .catch(() => {});
    }
  },

  mounted() {
    this.getUserList();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    },

    organizationName() {
      return this.$store.state.app.organizationName;
    }
  }
};
</script>
